export const PRIVACY_DEFINITION_CONTENT = {
  paragraph: 'For the purposes of these Terms and Conditions:',
};

export const PRIVACY_INTERPRETATION_CONTENT = {
  paragraph: 'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
};

export const PRIVACY_DEFINITIONS = [
  {
    id: 1,
    heading: 'Account',
    desc: ' means a unique account created for You to access our Service or parts of our Service',
  },
  {
    id: 2,
    heading: 'Company',
    desc: ' (referred as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Mugna Tech, 3rd floor Lot 8 Block 6, Turqouise St., Marfori Subd, Davao City, 8000, Davao del Sur, Philippines.',
  },
  {
    id: 3,
    heading: 'Cookies',
    desc: ' are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
  },
  { id: 4, heading: 'Country', desc: ' refers to: Philippines' },
  {
    id: 5,
    heading: 'Device',
    desc: ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },
  {
    id: 6,
    heading: 'Personal Data',
    desc: ' is any information that relates to an identified or identifiable individual.',
  },
  { id: 7, heading: 'Service', desc: ' refers to the Website.' },
  {
    id: 8,
    heading: 'Service Provider',
    desc: ' means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
  },
  {
    id: 9,
    heading: 'Usage Data',
    desc: ' refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  },
  {
    id: 10,
    heading: 'Website',
    desc: ' refers to Randomzer, accessible from https://www.randomzr.com/',
  },
  {
    id: 11,
    heading: 'You',
    desc: ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];
