import * as React from 'react';
import { HeadFC, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Definitions from '../components/Agreement/Definitions';
import Interpretation from '../components/Agreement/Interpretation';
import Loading from '../components/Loading';

import {
  PRIVACY_DEFINITIONS,
  PRIVACY_DEFINITION_CONTENT,
  PRIVACY_INTERPRETATION_CONTENT,
} from '../constants/privacy-policy';

import {
  termsContainer,
  interpretHeader,
  loadingWrapper,
} from '../styles/pages/privacy-policy/styles.module.css';
import Footer from '../components/Footer';

import { useAnimationLoading } from '../hooks/use-animation-loading-query';

const PrivacyPolicyPage: React.FC<PageProps> = () => {
  const loadingData = useAnimationLoading();

  return (
    <Layout>
      <div className={loadingWrapper}>
        <Loading data={loadingData} />
      </div>
      <main className={termsContainer}>
        <h1>Privacy Policy</h1>
        <p>Last updated: December 15, 2022</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the TermsFeed Privacy Policy Generator.
        </p>
        <h1 className={interpretHeader}>Interpretation and Definitions</h1>
        <Interpretation
          interpretationsContent={PRIVACY_INTERPRETATION_CONTENT}
        />
        <Definitions
          paragraphContent={PRIVACY_DEFINITION_CONTENT}
          definitions={PRIVACY_DEFINITIONS}
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const Head: HeadFC = () => <title>Privacy Policy</title>;
